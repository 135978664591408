import { Circle, Container, Flex, Progress, Square, Text, useColorModeValue } from "@chakra-ui/react";
import { IInfoListprop } from "../../../api/raceinfo";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { getHorseGradientStr, getHorseTextColor } from "../../../lib/utils";
import { motion } from "framer-motion";

// framer-motion에서 motion.div 사용
export default function InfoListTiny({ propType, propData, graphMaxValue }: IInfoListprop) {
    const TINY_COUNT = 8;
    const TINY_HEIGHT = "11%";

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const negativeColor = useColorModeValue(lightTheme_colors.negativeColor, darkTheme_colors.negativeColor);
    const fontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const frontNameColor = useColorModeValue(lightTheme_colors.frontNameColor, darkTheme_colors.frontNameColor);

    const listVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <Flex paddingY={"0.1rem"} paddingX={"0.2rem"} h={"100%"} flexDirection={"column"} justifyContent={"space-around"}>
            {
                propData === undefined || propType === undefined || graphMaxValue === undefined ?
                    (
                        <Container rounded='md' boxShadow='dark-lg' bg={bgColor} height={`${100 / 12}%`} maxWidth={"95%"} fontSize={"2xl"} color={fontColor}>
                            <Text>로딩중...</Text>
                        </Container>
                    ) : (
                        propData.map((data, index) =>
                            index < TINY_COUNT && (
                                // motion.div로 애니메이션 적용
                                <motion.div
                                    key={`${data.no1}-${data.no2}`}
                                    initial="hidden"
                                    animate="visible"
                                    layout  // layout 속성 추가
                                    variants={listVariants}
                                    transition={{ duration: 0.5, ease: "easeInOut" }} // framer-motion의 transition 사용
                                    style={{
                                        width: "100%",
                                        height: TINY_HEIGHT,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        backgroundColor: bgColor,
                                        borderRadius: "0.5rem",
                                        color: fontColor
                                    }}
                                >
                                    <Flex justifyContent={"center"} alignItems={"center"} fontSize={"1.5rem"}>
                                        <Circle size={"2rem"} color={getHorseTextColor(data.no1)}
                                            bgGradient={getHorseGradientStr(data.no1)}
                                            border={"1px solid black"}
                                        >
                                            {data.no1}
                                        </Circle>
                                    </Flex>

                                    <Flex justifyContent={"center"} fontSize={"1rem"}>
                                        <Circle>-</Circle>
                                    </Flex>

                                    <Flex justifyContent={"center"} alignItems={"center"} fontSize={"1.5rem"}>
                                        <Circle size={"2rem"} color={getHorseTextColor(data.no2)}
                                            bgGradient={getHorseGradientStr(data.no2)}
                                            border={"1px solid black"}
                                        >
                                            {data.no2}
                                        </Circle>
                                    </Flex>

                                    <Flex justifyContent={"center"} alignItems={"center"}>
                                        {
                                            parseFloat(data.bd) < 10 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={negativeColor}>
                                                    10↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 20 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    20↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 30 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    30↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 40 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    40↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 50 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    50↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 60 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    60↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 70 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    70↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 80 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    80↓
                                                </Square>
                                            ) : parseFloat(data.bd) < 90 ? (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor} color={frontNameColor}>
                                                    90↓
                                                </Square>
                                            ) : (
                                                <Square size={"2rem"} borderRadius={"xl"} bg={mainBgColor}>
                                                    100↓
                                                </Square>
                                            )
                                        }
                                    </Flex>

                                    <Flex justifyContent={"center"} alignItems={"center"} width={"4rem"} marginLeft={"1rem"}>
                                        <Progress
                                            value={propType === "numberOrder" ? (
                                                (parseFloat(data.number[0]) / graphMaxValue) * 100
                                            ) : (
                                                ((parseFloat(data.bd) * parseFloat(data.number[0])) / graphMaxValue) * 100
                                            )}
                                            size="md"
                                            width="100%"
                                            colorScheme="green"
                                            borderRadius="md"
                                            bg="rgba(255, 0, 0, 0.3)"
                                            height="1.8rem"
                                            border="2px solid #3182CE"
                                            sx={{
                                                '& > div': {
                                                    transition: 'width 0.6s ease',
                                                    backgroundImage: 'linear-gradient(90deg, #38B2AC, #3182CE)',
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                                }
                                            }}
                                        />
                                    </Flex>
                                </motion.div>
                            )
                        )
                    )
            }
        </Flex>
    )
}
