import { Box, Center, Grid, GridItem, Image, useColorModeValue, VStack } from "@chakra-ui/react"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserInfoAtom } from "../atoms/user";
import { darkTheme_colors, lightTheme_colors } from "../theme";


export default function LoginFirst() {
    const userinfo = useRecoilValue(UserInfoAtom)
    const navigate = useNavigate();
    useEffect(() => {
        if (!(userinfo.username === "")) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userinfo]);


    // 테마 관련 코드
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(1, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} rowSpan={1} bg={mainBgColor} >
                <Box borderRadius={"xl"} width={"100%"} height={"100%"} >
                    <Image objectFit={"cover"} h={"41vh"} width={"98.5vw"} borderRadius={"xl"} src="https://c.pxhere.com/images/06/83/f6bb4ba69449e6b1fb5600bb1daf-1588287.jpg!d" />
                </Box>
            </GridItem>
            <GridItem borderRadius={"xl"} rowSpan={1} bg={mainBgColor} color={"white"}>
                <VStack height={"100%"} justifyContent={"center"}>
                    <Center fontSize={"2rem"}>로그인후 이용가능합니다.</Center>
                    <Center fontSize={"1rem"}>회원가입을 하시면 관리자의 승인을 기다려야 합니다.</Center>
                </VStack>
            </GridItem>


        </Grid>

    )
}


