import axios from "axios";

// CSRF 토큰을 쿠키에서 가져오기
const getCSRFToken = () => {
    const name = 'csrftoken';
    const cookieString = document.cookie.split('; ').find(row => row.startsWith(name));
    if (cookieString) {
        return cookieString.split('=')[1];
    }
    return null; // 또는 기본값으로 빈 문자열을 반환할 수 있습니다.
};

// const BASE_URL = "http://127.0.0.1:8000/api/v1/";
const BASE_URL = "https://raceinfo.shop:8079/api/v1/";



export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        'X-CSRFToken': getCSRFToken() || '', // CSRF 토큰이 없을 경우 빈 문자열을 사용합니다.
    }
});

export interface IseverDetailReturn {
    response: {
        data: {
            detail: string;
        }
    }
}