import { Button, Center, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";

interface TestHeaderProps {
    isBBBToggled: boolean;
    isSBBBToggled: boolean;
    onToggleButton1: () => void;
    onToggleButton2: () => void;
}

export default function TestHeader({ isBBBToggled, isSBBBToggled, onToggleButton1, onToggleButton2 }: TestHeaderProps) {

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const SelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);


    return (
        <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor} >
            <Center flex={"6"}>
                <Text fontSize={"1.5rem"}>정보 아이디어 정리용 개발자 페이지.... 외부유출 금지!!</Text>
            </Center>

            <Flex flex={"4"} justifyContent={"space-around"} alignItems="center" >

                <Button
                    onClick={onToggleButton1}
                    isDisabled={isBBBToggled}
                    bg={isBBBToggled ? SelectColor : undefined} // 선택되면 화려한 배경색
                    color={isBBBToggled ? "white" : undefined} // 선택된 버튼은 흰색 텍스트
                >
                    {isBBBToggled ? "BBB 켜짐" : "BBB 꺼짐"}
                </Button>
                <Button
                    onClick={onToggleButton2}
                    isDisabled={isSBBBToggled}
                    bg={isSBBBToggled ? SelectColor : undefined} // 선택되면 화려한 배경색
                    color={isSBBBToggled ? "white" : undefined} // 선택된 버튼은 흰색 텍스트
                >
                    {isSBBBToggled ? "SBBB 켜짐" : "SBBB 꺼짐"}
                </Button>
            </Flex>

        </Flex>
    )
}