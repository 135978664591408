import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import Header from "../components/Header";
import Footer from "../components/Footer";


export default function Root() {
    return (
        <>
            <Box width={"100%"} height={"8vh"} >
                <Header />
            </Box>
            <Box width={"100%"} height={"85vh"}>
                <Outlet />
            </Box>
            <Box width={"100%"} height={"7vh"} >
                <Footer />
            </Box>
        </>
    )
}

