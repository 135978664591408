import { Box, Button, Center, Grid, GridItem, Image, Input, InputGroup, InputLeftElement, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast, VStack, useColorModeValue } from "@chakra-ui/react"
import { useForm } from "react-hook-form";
import useLoginOnlyPage from "../components/LoginProtectedPage";
import { FaBitcoin, FaMoneyBill, } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from 'react';
import { getDepasits, IUserDeposits, postDepasitCoin } from "../api/depasitCoin";
import { IseverDetailReturn } from "../api/base";
import { darkTheme_colors, lightTheme_colors } from "../theme";

interface IDepasitCoinForm {
    name: string,
    depasit: number,
}

export default function DepasitCoin() {
    useLoginOnlyPage()
    const toast = useToast();
    const [userDepasits, setuserDepasits] = useState<IUserDeposits[]>();
    const { register, handleSubmit, formState, reset } = useForm<IDepasitCoinForm>();

    // getDepasit
    useEffect(() => {
        getDepasitsmutaion.mutate()
    }, [])

    const getDepasitsmutaion = useMutation(getDepasits, {
        onSuccess: (data: IUserDeposits[]) => {
            setuserDepasits(data)
        }
    })

    function getDepasitSitualtion(type: string) {
        return {
            deposit_waiting: "승인대기",
            deposit_approval: "승인됨",
            withdraw_waiting: "출금대기",
            withdraw_approval: "출금승인됨",
        }[type];
    }

    // postDepasit
    const onSubmit = (FromDatas: IDepasitCoinForm) => {
        depositmutation.mutate({
            name: FromDatas.name,
            amount: FromDatas.depasit,
            situation: "deposit_waiting"
        })
    }

    const depositmutation = useMutation(postDepasitCoin, {
        onSuccess: (data) => {
            toast({
                position: 'bottom',
                title: "입금신청완료 ",
                description: "관리자의 승인후 사용가능합니다. ",
                status: "info",
                isClosable: true,
            });
            reset();
            getDepasitsmutaion.mutate();
        },
        onError: (data: IseverDetailReturn) => {
            console.log(data)
        }
    })

    // 테마 관련 코드
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const btnBgColor = useColorModeValue(lightTheme_colors.btnBgColor, darkTheme_colors.btnBgColor);
    const inputBgColor = useColorModeValue(lightTheme_colors.inputBgColor, darkTheme_colors.inputBgColor);
    const tableBgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const tableTextColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(1, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} rowSpan={1} bg={mainBgColor}>
                <Box borderRadius={"xl"} width={"100%"} height={"100%"} >
                    <Image objectFit={"cover"} h={"41vh"} width={"98.5vw"} borderRadius={"xl"} src="https://c.pxhere.com/images/06/83/f6bb4ba69449e6b1fb5600bb1daf-1588287.jpg!d" />
                </Box>
            </GridItem>
            <GridItem borderRadius={"xl"} rowSpan={1} bg={mainBgColor} color={tableTextColor} padding="1rem" overflow="hidden">
                <VStack height={"100%"} width={"100%"} justifyContent={"center"} overflow="hidden">
                    <TableContainer h={"100%"} width={"80%"} borderRadius={"xl"} bg={tableBgColor} color={tableTextColor} overflowY="scroll" >
                        <Table size='sm' variant='striped' colorScheme='teal'>
                            <TableCaption>오늘의 구매코인 내역</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>신청시간</Th>
                                    <Th>입금명</Th>
                                    <Th>상태</Th>
                                    <Th isNumeric>구매코인</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {userDepasits?.map((depasitData) => (
                                    <Tr key={depasitData.created_at}>
                                        <Td>{depasitData.created_at.split('T')[1].slice(0, 5)}</Td>
                                        <Td>{depasitData.name}</Td>
                                        <Td>{getDepasitSitualtion(depasitData.situation)}</Td>
                                        <Td isNumeric>{depasitData.amount}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>

                    <Center w={"50%"} h={"100%"} fontSize={"1rem"} bgColor={inputBgColor} color={tableTextColor} borderRadius={"xl"}>
                        <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={"4"}>
                            <Text>
                                코인 구매
                            </Text>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<FaMoneyBill color='gray.300' />}
                                />
                                <Input
                                    {...register("name", { required: "입금명이 입력되지 않았습니다.", })}
                                    type='text'
                                    placeholder='입금명을 입금하세요'
                                    bg={inputBgColor}
                                />
                            </InputGroup>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<FaBitcoin color='gray.300' />}
                                />
                                <Input
                                    {...register("depasit", { required: "구매코인입력", })}
                                    type='number'
                                    placeholder='구매코인( 1코인 : 1000 )'
                                    bg={inputBgColor}
                                />
                            </InputGroup>
                            <Button type="submit" colorScheme={"whatsapp"} w="100%" bg={btnBgColor}>
                                구매
                            </Button>
                        </Stack>
                    </Center>
                </VStack>
            </GridItem>
        </Grid>
    )
}
