import { useEffect, useRef } from 'react';

// 아래의 코드를 처음한번 실행하고, 그 이후에는 delay 시간이 지날때마다 callback 함수를 실행하게 바꿔줘

const useInterval = (callback, delay) => {
    const savedCallback = useRef(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const executeCallback = () => {
        savedCallback.current();
        };

        const timerId = setInterval(executeCallback, delay);

        return () => clearInterval(timerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};


export default useInterval;