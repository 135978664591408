import { Box, Flex, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";


import { useRecoilState } from "recoil";

import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getRaceLists, IRacelist } from "../../../api/racelist";
import useInterval from "../../../actions/customHook";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { getRegin } from "../../../lib/utils";
import { SelectedRaceAtom } from "../../../atoms/racelist";




/*
    - 경주목록 가져온다.    - 완료
    - 경주목록을 보여준다.  - 완료
    - 경주목록을 선택한다.(기본값 현제 진행경주, 없으면 마지막경주) - 완료
    - 기본 경주목록자동선택 - 완료
    - 선택한 경주목록을 atom에 저장한다. - 완료
*/

const tranceSituation = (type: string) => {
    return {
        normal: "정상",
        cancel: "취소",
        doing: "진행",
        done: "완료"
    }[type];
}


export default function BDRaceList() {

    // 경주 목록
    const [raceList, setRaceList] = useState<IRacelist[]>([])
    const recelistMutation = useMutation(getRaceLists, {
        onSuccess: (data: IRacelist[]) => {
            // 시간순 정렬 
            data.sort((a, b) => {
                const adate = new Date(a.departure_datetime)
                const bdate = new Date(b.departure_datetime)
                if (adate > bdate) {
                    return 1
                } else if (adate < bdate) {
                    return -1
                } else {
                    return 0
                }
            })
            setRaceList(data)
        },
        onError: (data: any) => {
            console.log(data)
        }
    })

    useEffect(() => {
        recelistMutation.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        recelistMutation.mutate()
    }, 30000);


    // 선택된 경주
    const [selectedRace, setSelectedRace] = useRecoilState<IRacelist>(SelectedRaceAtom)
    const [selectedRace_state, setSelectedRace_state] = useState<IRacelist>(selectedRace)


    const raceClick = (race: IRacelist) => {
        setSelectedRace(race)
        setSelectedRace_state(race)
        // setSelectedRace2(race)
    }
    useEffect(() => {
        // 지금 선택된 경주가 doing 경주이이면. raceList에서 doing 경주를 찾아서 선택한다.
        // 지금 경주내용과 선택된 경주 내용이 동일하지 않을떄만 setSelectedRace 업데이트 한다. 
        if (raceList.length === 0) return

        if (selectedRace.pk === 0 || selectedRace.situation === "doing") {
            for (const race of raceList) {
                if (race.situation === "doing") {
                    // 지금 경주내용과 동일하지 않을때만 업데이트
                    if (!(selectedRace.pk === race.pk)
                        || !(selectedRace.situation === race.situation)
                        || !(selectedRace.departure_datetime === race.departure_datetime)
                        || !(selectedRace.cancel === race.cancel)
                    ) {
                        setSelectedRace(race)
                    }
                    setSelectedRace_state(race)
                    break;
                }
            }
        } else {
            for (const race of raceList) {
                if (selectedRace.pk === race.pk) {
                    setSelectedRace(race)
                    setSelectedRace_state(race)
                    break;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [raceList]);


    // 테마 관련 코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);
    return (
        <Flex h={"100%"} justifyContent={'flex-start'} alignItems={"center"} flexDirection={"column"}  >
            {raceList.map((race) => (
                <Box
                    key={race.pk}
                    marginBottom={'2%'}
                    rounded='lg'
                    boxShadow='lg'
                    w={"100%"}
                    h={"3rem"}
                    overflow={"hidden"}
                >
                    <Grid as="button" rounded='lg' height={"100%"} width={"100%"} templateColumns='repeat(10, 1fr)' alignItems={"center"} bgColor={(selectedRace_state.pk === race.pk) ? receSelectColor : bgColor} fontSize={"1rem"} onClick={() => raceClick(race)} >
                        <GridItem colSpan={4}>
                            <Text>
                                {getRegin(race.region)} {race.number}경주
                            </Text>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <Text>
                                {race.departure_datetime.split('T')[1].slice(0, 5)}
                            </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Text>
                                {tranceSituation(race.situation)}
                            </Text>
                        </GridItem>
                    </Grid>
                </Box>
            )
            )}
        </Flex>
    )
}




