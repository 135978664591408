import { Box, Grid, GridItem, Image, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from 'react';
import { getRaceLists, IRacelist } from "../../api/racelist";
import { darkTheme_colors, lightTheme_colors } from "../../theme";
import RaceTime from "./Contents/RaceTimeCounter";
import HomeRaceList from "./Contents/Racelist";

export default function UserMain() {
    // useLoginOnlyPage()
    const { data: racelistsData, isLoading: racelistsDataLoding } = useQuery<IRacelist[]>(["racelistsData"], getRaceLists)
    const [race, setrace] = useState<IRacelist>();
    useEffect(() => {
        function isApple(element: any) {
            if (element.situation === 'doing' || element.situation === 'normal') {
                return true;
            }
        }
        if (!racelistsDataLoding) {
            setrace(racelistsData?.find(isApple))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [racelistsData])

    //테마 관련코드
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    return (
        <Grid
            padding={"0.2rem"}
            h='100%'
            templateRows='repeat(20, 1fr)'
            templateColumns='repeat(1, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} rowSpan={6} bg={mainBgColor} >
                <Box borderRadius={"xl"} width={"100%"} height={"100%"} fontSize={""}>
                    <Image objectFit={"cover"} h={"23.5vh"} width={"98.5vw"} borderRadius={"xl"} src="https://c.pxhere.com/images/06/83/f6bb4ba69449e6b1fb5600bb1daf-1588287.jpg!d" />
                </Box>
            </GridItem>
            <GridItem borderRadius={"xl"} rowSpan={1} bg={mainBgColor} color={mainFontColor}>
                <RaceTime racedata={race} />
            </GridItem>
            <GridItem
                borderRadius={"xl"} w={"100%"} padding={"0.5rem"}
                rowSpan={13} bg={mainBgColor} color={mainFontColor}
            >
                <HomeRaceList dataLoding={racelistsDataLoding} datas={racelistsData} />
            </GridItem>
        </Grid>
    )
}