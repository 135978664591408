import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IsLoginAtom } from "../atoms/user";

export default function useLoginProtectedPage() {
    const isLogin = useRecoilValue(IsLoginAtom)
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogin) {
            navigate("/loginfirst");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

}