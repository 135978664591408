import { useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Text, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";

type DataEntry = [number, number, number, string];

interface SBBTableProps {
    pageList: number[];
    SSBdata: DataEntry[];
    handleToggle: () => void;
}


export default function SBBTable({ pageList, SSBdata, handleToggle }: SBBTableProps) {
    const [page, setPage] = useState(1); // 현재 페이지 번호
    const maxRow = 16;
    const maxCol = 16;

    // 현재 페이지까지의 데이터를 필터링
    const pageData = SSBdata.filter(item => item[0] <= page);

    // 페이지에 맞는 값 가져오기
    const getValue = (no1: number, no2: number, no3: number) => {
        // no1, no2, no3 값을 정렬하여 가장 작은 수, 중간 수, 큰 수로 사용

        const sortedNumbers = [no1, no2, no3].sort((a, b) => a - b);
        const [smallest, middle, largest] = sortedNumbers;

        // pageData에서 정렬된 값과 일치하는 항목 찾기
        const entry = pageData.find(item => item[0] === smallest && item[1] === middle && item[2] === largest);

        return entry ? entry[3] === "-" ? entry[3] : parseFloat(entry[3]) / 10 : "-";
    };


    // 테마에 따른 색상 설정
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);


    const highlightColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const negativeColor = useColorModeValue(lightTheme_colors.negativeColor, darkTheme_colors.negativeColor);
    const bBlueColor = useColorModeValue(lightTheme_colors.bBlueColor, darkTheme_colors.bBlueColor);

    const getColor = (value: number) => {
        if (value < 10.0) return negativeColor;
        if (value < 100.0) return bBlueColor;
        return mainFontColor;
    };


    return (
        <Box w="100%" h="100%" overflow="auto" p={1}>
            <Box display="flex" justifyContent="center" mb={4}>
                {pageList.map((no1) => (
                    <Button
                        key={no1}
                        onClick={() => setPage(no1)}
                        mx={1}
                        colorScheme={no1 === page ? "blue" : "gray"}
                    >
                        {no1}번 페이지
                    </Button>
                ))}
            </Box>

            <Table variant="simple" size="sm" textAlign="center" border="1px solid black" bg={bgColor}>
                <Thead>
                    <Tr>
                        {/* 왼쪽 상단 "출전 번호" 셀 */}
                        <Th border="1px solid black" width="5rem" bg={highlightColor}>
                            <Button
                                h={"100%"}
                                onClick={handleToggle}
                                fontSize="md"
                                textAlign="center"
                                bg={highlightColor}
                            >
                                번호
                            </Button>
                        </Th>
                        {/* 상단 1~16 표시 */}
                        {Array.from({ length: maxCol }, (_, i) => (
                            <Th key={i + 1} border="1px solid black" width="5rem" bg={highlightColor}>
                                <Text fontSize="lg" textAlign="center">{i + 1}</Text>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {Array.from({ length: maxRow }, (_, rowIndex) => (
                        <Tr key={rowIndex + 1}>
                            {/* 왼쪽 1~16 표시 */}
                            <Td border="1px solid black" width="5rem" bg={highlightColor}>
                                <Text fontSize="lg" textAlign="center">{rowIndex + 1}</Text>
                            </Td>
                            {Array.from({ length: maxCol }, (_, colIndex) => {
                                const no2 = rowIndex + 1;
                                const no3 = colIndex + 1;

                                // 대각선 아래쪽일 경우 "-" 표시
                                if (colIndex < rowIndex) {
                                    return (
                                        <Td key={colIndex + 1} border="1px solid black" width="5rem">
                                            <Text fontSize="lg" textAlign="right">-</Text>
                                        </Td>
                                    );
                                }

                                const value = getValue(page, no2, no3);
                                return (
                                    <Td
                                        key={colIndex + 1}
                                        border="1px solid black"
                                        width="5rem"
                                        color={typeof value === "number" ? getColor(value) : mainFontColor}
                                    >
                                        <Text fontSize="lg" textAlign="right">
                                            {typeof value === "number" ? value.toFixed(1) : value}
                                        </Text>
                                    </Td>
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}