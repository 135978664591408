import { Container, Flex, Grid, GridItem, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { IRacelist } from "../../../api/racelist";
import { DoingRaceAtom } from "../../../atoms/racelist";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { getRegin } from "../../../lib/utils";

interface IHomeRaceListprop {
    dataLoding: boolean;
    datas: IRacelist[] | undefined;
}

export default function HomeRaceList({ dataLoding, datas }: IHomeRaceListprop) {
    // useLoginOnlyPage()
    const setDoingRace = useSetRecoilState<IRacelist>(DoingRaceAtom)
    function getSitualtion(type: string) {
        return {
            normal: "정상",
            cancel: "취소",
            doing: "진행",
            done: "완료"
        }[type];
    }
    useEffect(() => {
        const doingRace = datas?.find(race => race.situation === "doing");
        if (doingRace) {
            setDoingRace(doingRace);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datas])

    //테마 관련코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const listDeactivateColor2 = useColorModeValue(lightTheme_colors['listDeactivateColor2'], darkTheme_colors['listDeactivateColor2']);
    const listActivateColor = useColorModeValue(lightTheme_colors['listActivateColor'], darkTheme_colors['listActivateColor']);
    const listDeactivateColor = useColorModeValue(lightTheme_colors['listDeactivateColor'], darkTheme_colors['listDeactivateColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);
    return (
        <Container
            w={"100%"} h={"100%"}
            borderRadius={"xl"}
            padding={"0.5rem"}
            bg={bgColor}
            overflowY="auto"
            overflowX="hidden"
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {dataLoding ? (
                <Container>
                    <Spinner></Spinner>
                </Container>
            ) : (
                <Flex h={"100%"} borderRadius={"xl"} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"column"} bg={bgColor} >
                    {datas?.map((race) => (
                        <Container
                            key={race.pk}
                            rounded='md'
                            boxShadow='lg'
                            bg={
                                race.is_paied ?
                                    getSitualtion(race.situation) === "정상" ? listDeactivateColor2 :
                                        getSitualtion(race.situation) === "진행" ? listActivateColor : listDeactivateColor
                                    :
                                    getSitualtion(race.situation) === "정상" ? listActivateColor :
                                        getSitualtion(race.situation) === "진행" ? mainBgColor : listDeactivateColor
                            }
                            height={"xl"}
                            maxWidth={"95%"}
                            fontSize={"lg"}
                            mb={"0.5rem"}
                        >
                            {
                                race.is_paied ? (
                                    race.situation === "done" || race.situation === "doing" ? (
                                        <Link to={`raceinfo/${race.pk}`}>
                                            <Grid height={"100%"} width={"100%"} templateColumns='repeat(10, 1fr)' alignItems={"center"}>
                                                <GridItem colSpan={5}>
                                                    <Flex justifyContent={"center"}>
                                                        <Text>{getRegin(race.region)} {race.number}경주 ({race.hourses})</Text>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colSpan={3}>
                                                    <Flex justifyContent={"center"}>
                                                        <Text>
                                                            {race.departure_datetime.split('T')[1].slice(0, 5)}
                                                        </Text>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colSpan={2}>
                                                    <Flex justifyContent={"center"} overflow={"hidden"}>
                                                        <Text>
                                                            {getSitualtion(race.situation)}
                                                        </Text>
                                                    </Flex>
                                                </GridItem>
                                            </Grid>
                                        </Link>
                                    ) : (
                                        <Grid height={"100%"} width={"100%"} templateColumns='repeat(10, 1fr)' alignItems={"center"}>
                                            <GridItem colSpan={5}>
                                                <Flex justifyContent={"center"}>
                                                    <Text>{getRegin(race.region)} {race.number}경주 ({race.hourses})</Text>
                                                </Flex>
                                            </GridItem>
                                            <GridItem colSpan={3}>
                                                <Flex justifyContent={"center"}>
                                                    <Text>
                                                        {race.departure_datetime.split('T')[1].slice(0, 5)}
                                                    </Text>
                                                </Flex>
                                            </GridItem>
                                            <GridItem colSpan={2}>
                                                <Flex justifyContent={"center"} overflow={"hidden"}>
                                                    <Text>
                                                        {race.situation === "normal" ? "대기" : getSitualtion(race.situation)}
                                                    </Text>
                                                </Flex>
                                            </GridItem>
                                        </Grid>
                                    )

                                ) : (
                                    race.situation === "normal" ? (
                                        <Link to={`buyrace/${race.pk}`}>
                                            <Grid height={"100%"} width={"100%"} templateColumns='repeat(10, 1fr)' alignItems={"center"}>
                                                <GridItem colSpan={5}>
                                                    <Flex justifyContent={"center"}>
                                                        <Text >{
                                                            getRegin(race.region)
                                                        }
                                                            {race.number}경주 ({race.hourses})</Text>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colSpan={3}>
                                                    <Flex justifyContent={"center"}>
                                                        <Text>
                                                            {race.departure_datetime.split('T')[1].slice(0, 5)}
                                                        </Text>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colSpan={2}>
                                                    <Flex justifyContent={"center"} overflow={"hidden"}>
                                                        <Text>
                                                            {race.situation === "normal" ? "조회" : getSitualtion(race.situation)}
                                                        </Text>
                                                    </Flex>
                                                </GridItem>
                                            </Grid>
                                        </Link>
                                    ) : (
                                        <Grid height={"100%"} width={"100%"} templateColumns='repeat(10, 1fr)' alignItems={"center"}>
                                            <GridItem colSpan={5}>
                                                <Flex justifyContent={"center"}>
                                                    <Text >{
                                                        getRegin(race.region)
                                                    }
                                                        {race.number}경주 ({race.hourses})</Text>
                                                </Flex>
                                            </GridItem>
                                            <GridItem colSpan={3}>
                                                <Flex justifyContent={"center"}>
                                                    <Text>
                                                        {race.departure_datetime.split('T')[1].slice(0, 5)}
                                                    </Text>
                                                </Flex>
                                            </GridItem>
                                            <GridItem colSpan={2}>
                                                <Flex justifyContent={"center"} overflow={"hidden"}>
                                                    <Text>
                                                        {race.situation === "normal" ? "조회" : getSitualtion(race.situation)}
                                                    </Text>
                                                </Flex>
                                            </GridItem>
                                        </Grid>
                                    )
                                )
                            }
                        </Container>
                    ))}
                </Flex>
            )}
        </Container>
    );
}




