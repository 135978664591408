import { useEffect, useState } from "react"
import { getPopRanking, IPopRankingProps } from "../../../api/popRanking"
import { useMutation } from "@tanstack/react-query"
import { Box, Circle, Flex, Progress, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react"
import { darkTheme_colors, lightTheme_colors } from "../../../theme"
import { getHorseGradientStr, getHorseTextColor } from "../../../lib/utils"

const horseData = [
    { horse_number: 1, horse_name: '야생화', rank_5: 0, rank_4: 0, rank_3: 0, rank_2: 2, rank_1: 4 },
    { horse_number: 2, horse_name: '행복한함안', rank_5: 0, rank_4: 0, rank_3: 0, rank_2: 0, rank_1: 0 },
    { horse_number: 3, horse_name: '스타대로', rank_5: 0, rank_4: 0, rank_3: 2, rank_2: 1, rank_1: 1 },
    { horse_number: 4, horse_name: '마가리타', rank_5: 14, rank_4: 3, rank_3: 0, rank_2: 0, rank_1: 0 },
    { horse_number: 5, horse_name: '어메이징풋', rank_5: 0, rank_4: 0, rank_3: 0, rank_2: 0, rank_1: 0 },
    { horse_number: 6, horse_name: '그레이트미스', rank_5: 0, rank_4: 1, rank_3: 6, rank_2: 4, rank_1: 4 },
    { horse_number: 7, horse_name: '파이어매직', rank_5: 0, rank_4: 0, rank_3: 2, rank_2: 6, rank_1: 1 },
    { horse_number: 8, horse_name: '파워사운드', rank_5: 3, rank_4: 12, rank_3: 2, rank_2: 0, rank_1: 0 },
    { horse_number: 9, horse_name: '디러블리퀸', rank_5: 0, rank_4: 0, rank_3: 0, rank_2: 0, rank_1: 0 },
    { horse_number: 10, horse_name: '글로리크라운', rank_5: 0, rank_4: 2, rank_3: 4, rank_2: 4, rank_1: 6 },
    { horse_number: 11, horse_name: '스파이스엑톤', rank_5: 0, rank_4: 0, rank_3: 2, rank_2: 2, rank_1: 0 },
    { horse_number: 12, horse_name: '파이어매직', rank_5: 0, rank_4: 0, rank_3: 2, rank_2: 6, rank_1: 1 },
    { horse_number: 13, horse_name: '파워사운드', rank_5: 3, rank_4: 12, rank_3: 2, rank_2: 0, rank_1: 0 },
    { horse_number: 14, horse_name: '디러블리퀸', rank_5: 0, rank_4: 0, rank_3: 0, rank_2: 0, rank_1: 0 },
    { horse_number: 15, horse_name: '글로리크라운', rank_5: 0, rank_4: 2, rank_3: 4, rank_2: 4, rank_1: 6 },
    { horse_number: 16, horse_name: '스파이스엑톤', rank_5: 0, rank_4: 0, rank_3: 2, rank_2: 2, rank_1: 0 },
];

export default function PopRanking({ race_pk }: IPopRankingProps) {
    const [popRankingData, setPopRankingData] = useState<any>([])

    const popRankingMutation = useMutation(getPopRanking, {
        onSuccess: (data: any) => {
            // console.log(data)
            // setPopRankingData(data)

            const popRankingData = JSON.parse(data.pop_ranking_str)
            console.log("popRankingData : ", popRankingData)

            setPopRankingData(popRankingData)
        }
    })

    useEffect(() => {
        console.log(race_pk + "popRanking")
        popRankingMutation.mutate({ race_pk })
    }, [])

    const calculatePopularity = (horse: any) => {
        return (
            horse.rank_5 * 5 +
            horse.rank_4 * 4 +
            horse.rank_3 * 3 +
            horse.rank_2 * 2 +
            horse.rank_1 * 1
        );
    };

    // Use theme colors
    const bgColor = useColorModeValue(
        lightTheme_colors.bgColor,
        darkTheme_colors.bgColor
    );
    const mainBgColor = useColorModeValue(
        lightTheme_colors.mainBgColor,
        darkTheme_colors.mainBgColor
    );
    const fontColor = useColorModeValue(
        lightTheme_colors.mainFontColor,
        darkTheme_colors.mainFontColor
    );
    const borderColor = useColorModeValue(
        lightTheme_colors.borderColor,
        darkTheme_colors.borderColor
    );
    const impossibleColor = useColorModeValue(
        lightTheme_colors.impossibleColor,
        darkTheme_colors.impossibleColor
    );
    const progressBgColor = useColorModeValue(
        lightTheme_colors.progressBgColor,
        darkTheme_colors.progressBgColor
    );
    const scrollBarColor = useColorModeValue(
        lightTheme_colors.scrollBarColor,
        darkTheme_colors.scrollBarColor
    );
    const scrollBarThumbColor = useColorModeValue(
        lightTheme_colors.scrollBarThumbColor,
        darkTheme_colors.scrollBarThumbColor
    );
    const scrollBarThumbHoverColor = useColorModeValue(
        lightTheme_colors.scrollBarThumbHoverColor,
        darkTheme_colors.scrollBarThumbHoverColor
    );
    const listDeactivateColor3 = useColorModeValue(
        lightTheme_colors.listDeactivateColor3,
        darkTheme_colors.listDeactivateColor3
    );
    const listDeactivateColor = useColorModeValue(
        lightTheme_colors.listDeactivateColor,
        darkTheme_colors.listDeactivateColor
    );
    const bgHoverColor = useColorModeValue(
        lightTheme_colors.bgHoverColor,
        darkTheme_colors.bgHoverColor
    );

    return (
        <Box width="100%" height="100%">
            <TableContainer
                height="100%"
                overflowY="auto"
                css={{
                    "&::-webkit-scrollbar": {
                        width: "8px",
                        backgroundColor: scrollBarColor,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: scrollBarThumbColor,
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: scrollBarThumbHoverColor,
                    },
                }}
            >
                <Table
                    variant="simple"
                    size="sm"
                    sx={{ tableLayout: "fixed" }}
                    bg={bgColor}
                    color={fontColor}
                >
                    <Thead
                        position="sticky"
                        top="0"
                        bg={mainBgColor}
                        zIndex="1"
                        boxShadow="md"
                    >
                        <Tr>
                            <Th width="1.5rem" textAlign="center">
                                마 번
                            </Th>
                            <Th width="6rem" textAlign="center">
                                마 명
                            </Th>
                            <Th width="1rem" textAlign="center">
                                ★
                            </Th>
                            <Th width="1rem" textAlign="center">
                                ◎
                            </Th>
                            <Th width="1rem" textAlign="center">
                                ○
                            </Th>
                            <Th width="1rem" textAlign="center">
                                △
                            </Th>
                            <Th width="1rem" textAlign="center">
                                ※
                            </Th>
                            <Th width="6rem" textAlign="center">
                                인기도
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {popRankingData.map((horse: any, index: number) => {
                            const popularityScore = calculatePopularity(horse);
                            return (
                                <Tr
                                    key={horse.horse_number}
                                    height="1.5rem"
                                    bg={index % 2 === 0 ? listDeactivateColor : listDeactivateColor3}
                                    _hover={{
                                        bg: bgHoverColor,
                                    }}
                                >
                                    <Td width="1.5rem" textAlign="center" py="0.2rem" fontSize="0.8rem">
                                        <Circle size={"2rem"} color={getHorseTextColor(horse.horse_number)}
                                            bgGradient={getHorseGradientStr(horse.horse_number)}
                                            border={"1px solid black"}
                                        >
                                            {horse.horse_number}
                                        </Circle>
                                    </Td>
                                    <Td width="6rem" overflow="hidden" textAlign="center">
                                        <Text
                                            overflow="hidden"
                                            whiteSpace="nowrap"
                                            textOverflow="ellipsis"
                                            fontWeight="bold"
                                        >
                                            {horse.horse_name}
                                        </Text>
                                    </Td>
                                    <Td width="1rem" textAlign="center">
                                        {horse.rank_5}
                                    </Td>
                                    <Td width="1rem" textAlign="center">
                                        {horse.rank_4}
                                    </Td>
                                    <Td width="1rem" textAlign="center">
                                        {horse.rank_3}
                                    </Td>
                                    <Td width="1rem" textAlign="center">
                                        {horse.rank_2}
                                    </Td>
                                    <Td width="1rem" textAlign="center">
                                        {horse.rank_1}
                                    </Td>
                                    <Td width="6rem">
                                        <Flex align="center">
                                            <Progress
                                                value={popularityScore}
                                                max={100}
                                                colorScheme="green"
                                                size="sm"
                                                flex="1"
                                                bg={progressBgColor}
                                                borderRadius="md"
                                                height="1rem"
                                                border={`1px solid ${borderColor}`}
                                                sx={{
                                                    "& > div": {
                                                        transition: "width 0.6s ease",
                                                        backgroundImage:
                                                            "linear-gradient(90deg, #38B2AC, #3182CE)",
                                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                                                    },
                                                }}
                                            />
                                            <Box
                                                as="span"
                                                ml={2}
                                                whiteSpace="nowrap"
                                                fontWeight="bold"
                                                color={impossibleColor}
                                            >
                                                {popularityScore}%
                                            </Box>
                                        </Flex>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}


/**
 * 
[
    {
        "horse_number": 1,
        "horse_name": "야생화",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 0,
        "rank_2": 2,
        "rank_1": 4
    },
    {
        "horse_number": 2,
        "horse_name": "행복한함안",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 0,
        "rank_2": 0,
        "rank_1": 0
    },
    {
        "horse_number": 3,
        "horse_name": "스타대로",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 2,
        "rank_2": 1,
        "rank_1": 1
    },
    {
        "horse_number": 4,
        "horse_name": "마가리타",
        "rank_5": 14,
        "rank_4": 3,
        "rank_3": 0,
        "rank_2": 0,
        "rank_1": 0
    },
    {
        "horse_number": 5,
        "horse_name": "어메이징풋",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 0,
        "rank_2": 0,
        "rank_1": 0
    },
    {
        "horse_number": 6,
        "horse_name": "그레이트미스",
        "rank_5": 0,
        "rank_4": 1,
        "rank_3": 6,
        "rank_2": 4,
        "rank_1": 4
    },
    {
        "horse_number": 7,
        "horse_name": "파이어매직",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 2,
        "rank_2": 6,
        "rank_1": 1
    },
    {
        "horse_number": 8,
        "horse_name": "파워사운드",
        "rank_5": 3,
        "rank_4": 12,
        "rank_3": 2,
        "rank_2": 0,
        "rank_1": 0
    },
    {
        "horse_number": 9,
        "horse_name": "디러블리퀸",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 0,
        "rank_2": 0,
        "rank_1": 0
    },
    {
        "horse_number": 10,
        "horse_name": "글로리크라운",
        "rank_5": 0,
        "rank_4": 2,
        "rank_3": 4,
        "rank_2": 4,
        "rank_1": 6
    },
    {
        "horse_number": 11,
        "horse_name": "스파이스엑톤",
        "rank_5": 0,
        "rank_4": 0,
        "rank_3": 2,
        "rank_2": 2,
        "rank_1": 0
    }
]
 */