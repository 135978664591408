import { atom } from "recoil";


// todo 옵션 선택에 관한 atom 나중에 통합할것. 


// raceInfoPageControl
export const BaseInfoTagAtom = atom({
    key: "BaseInfoTagAtom",
    default: true,

})
export const raceInfoPageInfoDataIs12Atom = atom({
    key: "raceInfoPageInfoDataIs12Atom",
    default: true,
})
export const raceinfoIsPauseAtom = atom({
    key: "raceinfoIsPauseAtom",
    default: false,
})

export const PopRankingIsShowAtom = atom({
    key: "PopRankingIsShowAtom",
    default: false,
})