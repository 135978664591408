import { axiosInstance } from "./base";

export interface IBBBdata {
    race_pk: number;
}

export const getBBBdata = ({ race_pk }: IBBBdata) => (
    axiosInstance.get(
        `beadang_bok/distributor/${race_pk}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


export interface ISBBdata {
    race_pk: number;
    no1: number;
}

export const getSBBdata = ({ race_pk, no1 }: ISBBdata) => (
    axiosInstance.get(
        `beadang_sambok/distributor/${race_pk}?no1=${no1}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)