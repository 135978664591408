import { axiosInstance } from "./base";

//depasitCoin
export interface IUserDeposits {
    pk: number,
    user: {
        username: string,
        authority: string,
    }
    name: string,
    amount: number,
    situation: string,
    created_at: string,
    updated_at: string,
}
export interface IDepositsprops {
    name: string,
    amount: number,
    situation: string,
}

export const getDepasits = () => (
    axiosInstance.get(
        `deposit/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const postDepasitCoin = (formData: IDepositsprops) => (
    axiosInstance.post(
        `deposit/`,
        formData,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)