import { axiosInstance } from "./base";

export const getRaceInfo = (queryKey: any) => (
    axiosInstance.get(
        `raceinfo/${queryKey}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


export interface Idata {
    no1: string,
    no2: string,
    bd: string,
    number: string
}

export interface IInfoListprop {
    propType: string | undefined,
    propData: Idata[] | undefined,
    graphMaxValue: number | undefined,
}