import { Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useLoginOnlyPage from "../../components/LoginProtectedPage";
import { useEffect, useState } from 'react';
import useInterval from "../../actions/customHook";
import { useRecoilState, useRecoilValue } from "recoil";

import { getRaceLists, IRacelist } from "../../api/racelist";
import { getRaceInfo, Idata, IInfoListprop } from "../../api/raceinfo";
import { BaseInfoTagAtom, PopRankingIsShowAtom, raceinfoIsPauseAtom, raceInfoPageInfoDataIs12Atom } from "../../atoms/raceinfoPage";
import RaceTime from "../UserMain/Contents/RaceTimeCounter";
import { lightTheme_colors, darkTheme_colors } from "../../theme";
import TopFive from "./contents/TopFive";
import InfoList from "./contents/InfoList";
import InfoListMore from "./contents/InfoListMore";
import InfoListTiny from "./contents/InfoListTiny";
import InfoTopList from "./contents/InfoTopList";

import dayjs from 'dayjs';
import PopRanking from "./contents/PopRanking";

interface IData {
    no1: string;
    no2: string;
    bd: string;
    number: string[];
}


function getRightMaxBdNumberProduct(data: IData[]): number {
    return Math.max(...data.map(item => {
        const numberValue = item.number[0] === '-' ? 0 : parseFloat(item.number[0]);
        return parseFloat(item.bd) * numberValue;
    }));
}

export default function RaceInfo() {
    // 페이지 권한, 파라미터.
    useLoginOnlyPage();
    const { racePk } = useParams();
    const [infoTopData, setInfoTopData] = useState<Idata[]>();



    // 진행중 경기 정보 가져오기
    const [race, setrace] = useState<IRacelist>();
    const { data: racelistsData, isLoading: racelistsDataLoding } = useQuery<IRacelist[]>(["racelistsData"], getRaceLists, {
        onSuccess: (data) => {
            const ondoingRace = data.find(element => element.situation === 'doing');
            if (ondoingRace) {
                setrace(ondoingRace);
            }
        },
    });

    // 4분전 랭킹 보이기 
    const [PopRankingIsShow, setPopRankingIsShow] = useRecoilState(PopRankingIsShowAtom);

    useEffect(() => {
        if (!race) {
            return;
        }
        const departureTime = dayjs(race.departure_datetime);

        const checkTime = () => {
            const currentTime = dayjs();
            const timeDifference = departureTime.diff(currentTime, 'minute');

            if (timeDifference > 3) {
                setPopRankingIsShow(true);
            } else if (timeDifference <= 3) {
                setPopRankingIsShow(false);
            }
        };

        // 처음에 즉시 한 번 체크
        checkTime();

        // 1초마다 시간 체크
        const interval = setInterval(checkTime, 1000);

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => clearInterval(interval);
    }, [race]);


    // info 데이터 가져오기
    const raceinfoMutation = useMutation(getRaceInfo, {
        onSuccess: (data: any) => {
            setrace(data.race);

            makeDataOrderByNumber(data.info_str);

            makeDataOrderByTotal(data.info_str);
            makeTop5(data.info_ranking_str);
            setInfoTopData(data.b_ranking);
        },
        onError: (data: any) => {
            console.log(data);
        },
    });
    useEffect(() => {
        raceinfoMutation.mutate(racePk);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 2초 간격으로 데이터 갱신
    const raceInfoIsPause = useRecoilValue(raceinfoIsPauseAtom);
    useInterval(() => {
        if (!raceInfoIsPause) {
            raceinfoMutation.mutate(racePk);
        }
    }, 2000);


    // numberDataLists
    const [DataOrdernumber, setOrdernumber] = useState<IInfoListprop>();
    const [DataOrdernumber_limite_12, setOrdernumber_limite_12] = useState<IInfoListprop>();

    // totalDataLists
    const [DataOrderTotal, setDataOrderTotal] = useState<IInfoListprop>();
    const [DataDataOrderTotal_limite_12, setDataOrderTotal_limite_12] = useState<IInfoListprop>();

    // 기본정보 칸
    const InfoDataIs12 = useRecoilValue(raceInfoPageInfoDataIs12Atom);

    // 왼쪽 데이터 정렬 함수
    const makeDataOrderByNumber = (info_str: string) => {
        const infoDataList = JSON.parse(info_str);
        let numberOrder_imsi = JSON.parse(JSON.stringify(infoDataList)); // 깊은 복사
        numberOrder_imsi.sort((a: Idata, b: Idata) => parseInt(b.number) - parseInt(a.number));

        setOrdernumber({
            propType: "numberOrder",
            propData: numberOrder_imsi.slice(0, 25),
            graphMaxValue: Math.max(...numberOrder_imsi.slice(0, 25).map((data: any) => parseFloat(data.number[0])))
        });

        setOrdernumber_limite_12({
            propType: "numberOrder",
            propData: numberOrder_imsi.slice(0, 12),
            graphMaxValue: Math.max(...numberOrder_imsi.slice(0, 12).map((data: any) => parseFloat(data.number[0])))
        });
    };

    // 오른쪽 데이터 정렬 함수
    const makeDataOrderByTotal = (info_str: string) => {
        const infoDataList = JSON.parse(info_str);
        let totalOrder_imsi = JSON.parse(JSON.stringify(infoDataList));
        totalOrder_imsi.sort((a: Idata, b: Idata) => (parseFloat(b.bd) * parseFloat(b.number)) - (parseFloat(a.bd) * parseFloat(a.number)));

        const maxBdNumberProduct = getRightMaxBdNumberProduct(totalOrder_imsi);

        setDataOrderTotal({
            propType: "totalOrder",
            propData: totalOrder_imsi.slice(0, 25),
            graphMaxValue: maxBdNumberProduct
        });

        setDataOrderTotal_limite_12({
            propType: "totalOrder",
            propData: totalOrder_imsi.slice(0, 12),
            graphMaxValue: maxBdNumberProduct
        });
    };

    // 상단 5개 랭킹
    const [top5Data, setTop5Data] = useState<[]>([]);
    const makeTop5 = (info_ranking_str: string) => {
        const top5_parsed_data = JSON.parse(info_ranking_str);
        if (top5_parsed_data.length > 0) {
            setTop5Data(top5_parsed_data);
        }
    };

    // 목록정보(배당정보) 화면 전환 여부
    const IsbaseInfo = useRecoilValue(BaseInfoTagAtom);


    // 테마에 따른 색상 설정
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const highlightColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const fontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    return (
        <Grid
            padding={"0.2rem"}
            h="100%"
            templateRows="repeat(10, 1fr)"
            templateColumns="repeat(2, 1fr)"
            gap={1}
            bg={bgColor}
        >
            <GridItem borderRadius={"2xl"} rowSpan={1} colSpan={2} bg={mainBgColor} color={fontColor}>
                <RaceTime racedata={race} />
            </GridItem>
            <GridItem rowSpan={1} colSpan={2} bg={bgColor}>
                <TopFive top5Data={top5Data} />
            </GridItem>

            {IsbaseInfo ? (
                InfoDataIs12 ? (
                    <>
                        <GridItem rowSpan={8} colSpan={1} bg={mainBgColor}>
                            {/* <InfoList InfoListData={DataOrdernumber_limite_12} /> */}
                            {DataOrdernumber_limite_12 && (
                                <InfoList
                                    propType={DataOrdernumber_limite_12.propType}
                                    propData={DataOrdernumber_limite_12.propData}
                                    graphMaxValue={DataOrdernumber_limite_12.graphMaxValue}
                                />
                            )}
                        </GridItem>
                        <GridItem rowSpan={8} colSpan={1} bg={mainBgColor}>
                            {/* <InfoList propType={DataDataOrderTotal_limite_12.propType} propData={DataDataOrderTotal_limite_12.propData} /> */}
                            {DataDataOrderTotal_limite_12 && (
                                <InfoList
                                    propType={DataDataOrderTotal_limite_12.propType}
                                    propData={DataDataOrderTotal_limite_12.propData}
                                    graphMaxValue={DataDataOrderTotal_limite_12.graphMaxValue}
                                />
                            )}
                        </GridItem>
                    </>
                ) : (
                    <>
                        <GridItem rowSpan={8} colSpan={1} bg={highlightColor}>
                            {/* <InfoListMore propType={DataOrdernumber?.propType} propData={DataOrdernumber?.propData} /> */}
                            {DataOrdernumber && (
                                <InfoListMore
                                    propType={DataOrdernumber.propType}
                                    propData={DataOrdernumber.propData}
                                    graphMaxValue={DataOrdernumber.graphMaxValue}
                                />
                            )}
                        </GridItem>
                        <GridItem rowSpan={8} colSpan={1} bg={highlightColor}>
                            {/* <InfoListMore propType={DataOrderTotal?.propType} propData={DataOrderTotal?.propData} /> */}
                            {DataOrderTotal && (
                                <InfoListMore
                                    propType={DataOrderTotal.propType}
                                    propData={DataOrderTotal.propData}
                                    graphMaxValue={DataOrderTotal.graphMaxValue}
                                />
                            )}
                        </GridItem>
                    </>
                )
            ) : (
                PopRankingIsShow ? (
                    <>
                        <GridItem rowSpan={5} colSpan={2} bg={mainBgColor}>
                            {race && <PopRanking race_pk={race.pk} />}
                        </GridItem>
                        <GridItem rowSpan={4} colSpan={2} bg={highlightColor}>
                            <InfoTopList TopListdata={infoTopData} />
                        </GridItem>
                    </>
                ) : (
                    <>
                        <GridItem rowSpan={5} colSpan={1} bg={mainBgColor}>
                            {/* <InfoListTiny propType={DataOrdernumber_limite_12?.propType} propData={DataOrdernumber_limite_12?.propData} /> */}
                            {DataOrdernumber_limite_12 && (
                                <InfoListTiny
                                    propType={DataOrdernumber_limite_12.propType}
                                    propData={DataOrdernumber_limite_12.propData}
                                    graphMaxValue={DataOrdernumber_limite_12.graphMaxValue}
                                />
                            )}
                        </GridItem>
                        <GridItem rowSpan={5} colSpan={1} bg={mainBgColor}>
                            {/* <InfoListTiny propType={DataDataOrderTotal_limite_12?.propType} propData={DataDataOrderTotal_limite_12?.propData} /> */}
                            {DataDataOrderTotal_limite_12 && (
                                <InfoListTiny
                                    propType={DataDataOrderTotal_limite_12.propType}
                                    propData={DataDataOrderTotal_limite_12.propData}
                                    graphMaxValue={DataDataOrderTotal_limite_12.graphMaxValue}
                                />
                            )}
                        </GridItem>
                        <GridItem rowSpan={4} colSpan={2} bg={highlightColor}>
                            <InfoTopList TopListdata={infoTopData} />


                        </GridItem>
                    </>
                )
            )}
        </Grid>
    );
}



/**
 
 */