import { useRecoilValue } from "recoil";
import { SelectedRaceAtom } from "../../../atoms/racelist";
import { useMutation } from "@tanstack/react-query";
import { getBBBdata } from "../../../api/bbb";
import { useEffect, useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, useColorModeValue, Button } from "@chakra-ui/react";
import useInterval from "../../../actions/customHook";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";

interface TableData {
    row: number;
    col: number;
    value: number;
}


interface BBBDataItem {
    up_time: string;
    formattedData: TableData[];
}

export default function BBBpage() {
    const selectedRace = useRecoilValue(SelectedRaceAtom);
    const [tableData, setTableData] = useState<TableData[]>([]);

    const [tableData2, setTableData2] = useState<BBBDataItem[]>([]);

    const [isToggled, setIsToggled] = useState(true); // 토글 상태 관리

    const BBBdataMutation = useMutation(getBBBdata, {
        onSuccess: (data: any) => {
            if (data.length === 0 || data === undefined) {
                setTableData([]);
                setTableData2([]);
                return;
            }

            const target_BBBdata_list = data.map((item: any) => {
                const up_time = item.created_at.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":");

                const imsi = JSON.parse(item.data_str);

                const formattedData: TableData[] = imsi.filter((item: any) => item[2] !== "-")
                    .map((item: any) => ({
                        row: item[0],
                        col: item[1],
                        value: parseInt(item[2]) / 10,
                    }));

                return {
                    up_time: up_time,
                    formattedData: formattedData
                };
            })

            // 정렬을 뒤집자.
            const re_target_BBBdata_list = target_BBBdata_list.reverse();

            setTableData2(re_target_BBBdata_list);
            setTableData(re_target_BBBdata_list[0].formattedData);
        },
        onError: (error: any) => {
            console.log("Error fetching BBB data:", error);
        },
    });

    useEffect(() => {
        if (selectedRace.pk !== 0) {
            BBBdataMutation.mutate({ race_pk: selectedRace.pk });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    useInterval(() => {
        if (selectedRace.pk !== 0) {
            BBBdataMutation.mutate({ race_pk: selectedRace.pk });
        }
    }, 10000);

    const maxRow = 16;
    const maxCol = 16;

    const getValue = (row: number, col: number) => {
        const found = tableData.find((d) => d.row === row && d.col === col);
        return found ? found.value : "-";
    };


    // `row-col` 조합을 키로 하여 데이터 매핑
    const rowColDataMap = new Map();
    tableData2.forEach(({ up_time, formattedData }) => {
        formattedData.forEach(({ row, col, value }) => {
            const key = `${row}-${col}`;
            if (!rowColDataMap.has(key)) {
                rowColDataMap.set(key, { row, col, values: [] });
            }
            rowColDataMap.get(key).values.push({ up_time, value });
        });
    });

    // 테마에 따른 색상 설정
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const btnBgColor = useColorModeValue(lightTheme_colors.btnBgColor, darkTheme_colors.btnBgColor);

    const highlightColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const negativeColor = useColorModeValue(lightTheme_colors.negativeColor, darkTheme_colors.negativeColor);
    const bBlueColor = useColorModeValue(lightTheme_colors.bBlueColor, darkTheme_colors.bBlueColor);




    const getColor = (value: number) => {
        if (value < 10.0) return negativeColor;
        if (value < 100.0) return bBlueColor;
        return mainFontColor;
    };

    return (
        <Box w={"100%"} h={"100%"} overflow="auto" p={1}>
            {isToggled ? (
                <Table
                    variant="simple"
                    size="sm"
                    textAlign="center"
                    border="1px solid black"
                    color={mainFontColor}
                    bg={bgColor}
                >
                    <Thead>
                        <Tr>
                            {/* 왼쪽 상단 "번호" 셀 */}
                            <Th border="1px solid black" width="5rem" bg={highlightColor}>
                                <Button
                                    h={"100%"}
                                    onClick={() => setIsToggled(!isToggled)}
                                    fontSize="md"
                                    textAlign="center"
                                    bg={highlightColor}
                                >
                                    {isToggled ? "번호" : "번호"} {/* 텍스트도 토글 */}
                                </Button>
                            </Th>
                            {/* 상단 1~16 표시 */}
                            {[...Array(maxCol)].map((_, i) => (
                                <Th key={i + 1} border="1px solid black" width="5rem" bg={highlightColor}>
                                    <Text fontSize="lg" textAlign="center">{i + 1}</Text>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {[...Array(maxRow)].map((_, rowIndex) => (
                            <Tr key={rowIndex + 1}>
                                {/* 왼쪽 1~16 표시 */}
                                <Td border="1px solid black" width="5rem" bg={highlightColor}>
                                    <Text fontSize="lg" textAlign="center">{rowIndex + 1}</Text>
                                </Td>
                                {[...Array(maxCol)].map((_, colIndex) => {
                                    const value = getValue(rowIndex + 1, colIndex + 1);

                                    // 대각선에 1부터 16까지 숫자를 표시하고 배경색 구분
                                    if (rowIndex === colIndex) {
                                        return (
                                            <Td
                                                key={colIndex + 1}
                                                border="1px solid black"
                                                width="5rem"
                                                bg={highlightColor} // 대각선 셀의 배경색
                                            >
                                                <Text fontSize="lg" textAlign="center">
                                                    {rowIndex + 1}
                                                </Text>
                                            </Td>
                                        );
                                    }

                                    // 나머지 셀에 원래 값 또는 "-" 표시
                                    return (
                                        <Td key={colIndex + 1} border="1px solid black" width="5rem">
                                            {typeof value === "number" ? (
                                                <Text color={getColor(value)} fontSize="lg" textAlign="right">
                                                    {value.toFixed(1)}
                                                </Text>
                                            ) : (
                                                <Text fontSize="lg" textAlign="right">-</Text>
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

            ) : (
                <Table variant="simple" size="sm" textAlign="center" border="1px solid black" bg={bgColor} >
                    <Thead>
                        <Tr bg={highlightColor}>
                            <Th>
                                <Button
                                    h={"100%"}
                                    onClick={() => setIsToggled(!isToggled)}
                                    fontSize="md"
                                    textAlign="center"
                                    bg={highlightColor}
                                >
                                    {isToggled ? "번호" : "번호"} {/* 텍스트도 토글 */}
                                </Button>
                            </Th>
                            {[...tableData2.map((item) => item.up_time)].map((up_time, idx) => (
                                <Th key={idx}>{up_time}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Array.from(rowColDataMap.entries()).map(([key, { row, col, values }], rowIndex) => (
                            <Tr
                                key={key}
                                bg={rowIndex % 2 === 0 ? bgColor : btnBgColor} // 짝수 행은 연한 회색, 홀수 행은 흰색
                            >
                                <Td textAlign="right" bg={highlightColor}>
                                    {`${row} - ${col}`}
                                </Td>
                                {values.map(({ value }: { value: number }, idx: number) => (
                                    <Td key={idx}>
                                        <Text color={value < 10 ? "red" : value < 100 ? "blue" : mainFontColor} textAlign="right">
                                            {value.toFixed(1)}
                                        </Text>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
}




