import { useRecoilValue } from "recoil";
import { IsLoginAtom, UserInfoAtom } from "../../atoms/user";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../theme";
import BDRaceList from "./contents/RaceList";
import TestHeader from "./contents/testHeader";
import { SelectedRaceAtom } from "../../atoms/racelist";
import BBBpage from "./contents/BBBpabe";
import SBBpage from "./contents/SBBpage";

export default function TESTMain() {
    // 페이지 보안.
    const userinfo = useRecoilValue(UserInfoAtom)
    const navigate = useNavigate();

    useEffect(() => {
        if (userinfo.authority !== "distributor") {
            navigate("/loginfirst");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userinfo]);



    // console.log(selectedRace)

    // 토글버튼 기본적으로 버튼 1이 눌린 상태로 시작

    const [isBBBToggled, setIsBBBToggled] = useState(true);
    const [isSBBBToggled, setIsSBBBToggled] = useState(false);

    const toggleButton1 = () => {
        setIsBBBToggled(true);
        setIsSBBBToggled(false);
    };

    const toggleButton2 = () => {
        setIsBBBToggled(false);
        setIsSBBBToggled(true);
    };


    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(6, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} colSpan={1} rowSpan={10} bg={mainBgColor} >
                <BDRaceList />
            </GridItem>
            <GridItem borderRadius={"xl"} padding={"0.5rem"} colSpan={5} rowSpan={1} bg={mainBgColor} >

                <TestHeader
                    isBBBToggled={isBBBToggled}
                    isSBBBToggled={isSBBBToggled}
                    onToggleButton1={toggleButton1}
                    onToggleButton2={toggleButton2}
                />
            </GridItem>
            <GridItem borderRadius={"xl"} padding={"0.5rem"} colSpan={5} rowSpan={9} bg={mainBgColor} >
                {isBBBToggled && <BBBpage />}
                {isSBBBToggled && <SBBpage />}

            </GridItem>


        </Grid >
    )
}