import { axiosInstance } from "./base";

export interface IPopRankingProps {
    race_pk: number;
}

//popRanking
export const getPopRanking = ({ race_pk }: IPopRankingProps) => (
    axiosInstance.get(
        `popranking/${race_pk}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


