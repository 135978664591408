import { useRecoilValue } from "recoil";
import { SelectedRaceAtom } from "../../../atoms/racelist";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getSBBdata } from "../../../api/bbb";
import useInterval from "../../../actions/customHook";
import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import SBBTable from "./SBBTable";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";



// // 제외된 숫자 리스트를 제외하고 1부터 n까지의 숫자 배열을 반환하는 함수
// function getRemainingNumbers(n: number, exclude: number[]): number[] {
//     return Array.from({ length: n }, (_, i) => i + 1).filter(num => !exclude.includes(num)).slice(0, -2); // 끝에서 두 개의 요소 제외
// }


// 제외된 숫자 리스트를 제외하고 1부터 n까지의 숫자 배열을 반환하는 함수
function getRemainingNumbers2(n: number): number[] {
    return Array.from({ length: n }, (_, i) => i + 1).slice(0, -2); // 끝에서 두 개의 요소 제외
}


function generateCombinationsWithDefault(n: number) {
    const result = [];

    for (let i = 1; i <= n - 2; i++) {
        for (let j = i + 1; j <= n - 1; j++) {
            for (let k = j + 1; k <= n; k++) {
                result.push(
                    { no1: i, no2: j, no3: k, value: '-' }
                );
            }
        }
    }

    return result;
}


interface IssbTableData {
    no1: number;
    no2: number;
    no3: number;
    value: number;
}


interface ISBBDataItem {
    up_time: string;
    formattedData: IssbTableData[];
}

export default function SBBpage() {
    const [tableData2, setTableData2] = useState<ISBBDataItem[]>([]);


    const selectedRace = useRecoilValue(SelectedRaceAtom);

    const [isToggled, setIsToggled] = useState(true); // 토글 상태 관리

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };



    const imsiDataList = useRef<any[]>([]); // useRef로 데이터 목록 관리
    const SBBTabledata = useRef<[number, number, number, string][]>([]); // useRef로 데이터 목록 관리
    const SBBTablePageList = Array.from({ length: selectedRace.hourses }, (_, i) => i + 1); // 페이지 목록

    const SBBdataMutation = useMutation(getSBBdata, {
        onSuccess: (data, variables) => {
            data.sort((a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

            if (data.length === 0) {
                // 실패 시 빈 배열을 imsiDataList.current 배열에 추가
                imsiDataList.current.push({ no1: variables.no1, data: [] });
                return;
            }
            // 성공 시 결과를 imsiDataList.current 배열에 추가
            // 같은 `no1` 값을 가진 항목이 있는지 확인
            const existingIndex = imsiDataList.current.findIndex(item => item.no1 === variables.no1)

            if (existingIndex !== -1) {
                // 같은 `no1`이 있으면 해당 인덱스의 항목을 덮어씀
                imsiDataList.current[existingIndex] = { no1: variables.no1, data };
            } else {
                // 같은 `no1`이 없으면 새 항목 추가
                imsiDataList.current.push({ no1: variables.no1, data });
            }
        }
    });

    useEffect(() => {
        if (selectedRace.pk !== 0) {
            imsiDataList.current = []; // 데이터 목록 초기화
            // const target_num_list = getRemainingNumbers(selectedRace.hourses, JSON.parse(selectedRace.cancel));
            const target_num_list = getRemainingNumbers2(selectedRace.hourses);

            // 모든 비동기 작업이 완료된 후 가공 작업을 위해 Promise.all 사용
            Promise.all(
                target_num_list.map(num =>
                    SBBdataMutation.mutateAsync({ race_pk: selectedRace.pk, no1: num })
                )
            ).then(() => {
                imsiDataList.current.sort((a, b) => a.no1 - b.no1); // no1 기준으로 정렬               

                processImsiData();     // 필요한 후처리 로직을 여기에 작성
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);


    useInterval(() => {
        if (selectedRace.pk !== 0) {
            const target_num_list = getRemainingNumbers2(selectedRace.hourses);

            // 모든 비동기 작업이 완료된 후 가공 작업을 위해 Promise.all 사용
            Promise.all(
                target_num_list.map(num =>
                    SBBdataMutation.mutateAsync({ race_pk: selectedRace.pk, no1: num })
                )
            ).then(() => {
                imsiDataList.current.sort((a, b) => a.no1 - b.no1); // no1 기준으로 정렬               

                processImsiData();     // 필요한 후처리 로직을 여기에 작성
            });
        }
    }, 10000);


    // 데이터를 가공하는 함수
    const processImsiData = () => {
        // 여기서 imsiDataList.current 데이터를 가공할 수 있습니다.  

        // SBBTable 데이터를 가공
        let sambokData: any = [];
        imsiDataList.current.forEach((data) => {
            if (data.data.length === 0) {
                return;
            }
            // 데이터의 가장 마지막 데이터를 가져온다.
            const lastData = data.data[data.data.length - 1];
            sambokData = [...sambokData, ...JSON.parse(lastData.data_str)];
        });
        SBBTabledata.current = sambokData;


        // 표 데이터 가공
        // 기준데이터 정한다. (가장 마지막 번호(데이터가 있는))
        const targetLastData = imsiDataList.current[imsiDataList.current.length - 1].data;

        let targetdatalist = targetLastData.map((item: any) => {
            return item.created_at;
        });

        let target_imsi_data_list: any = []
        targetdatalist.forEach((item: any) => {
            let target_time_data2 = generateCombinationsWithDefault(selectedRace.hourses);
            imsiDataList.current.forEach((data) => {

                const matchedData = data.data.find((entry: any) => {
                    // created_at 문자열을 Date 객체로 변환하여 시간 비교
                    const itemTime = new Date(item).getTime();
                    const entryTime = new Date(entry.created_at).getTime();
                    return entryTime >= itemTime; // entryTime이 item보다 작거나 같으면 조건을 만족
                });

                if (matchedData) {
                    const parsedData = JSON.parse(matchedData.data_str);

                    parsedData.forEach((match: any) => {
                        const [no1, no2, no3, value] = match;

                        // target_time_data2에서 같은 no1, no2, no3를 가진 요소를 찾아서 value 덮어쓰기
                        const targetEntry = target_time_data2.find(
                            (entry: any) => entry.no1 === no1 && entry.no2 === no2 && entry.no3 === no3
                        );

                        if (targetEntry) {
                            // console.log("!!!!!!!!!! : ", value, typeof value);
                            targetEntry.value = value === "-" ? value : String(value / 10); // 기존 값 덮어쓰기
                        }
                    });
                }

            });

            const formattedData = {
                "up_time": item.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":"),
                "formattedData": target_time_data2
            }


            target_imsi_data_list.push(formattedData);
        });
        target_imsi_data_list = target_imsi_data_list.reverse() as ISBBDataItem[];

        setTableData2(target_imsi_data_list);
    };


    // `no1-no2-no3` 조합을 키로 하여 데이터 매핑
    const rowColDataMap = new Map();
    tableData2.forEach(({ up_time, formattedData }: any) => {
        formattedData.forEach(({ no1, no2, no3, value }: any) => {
            // no1-no2-no3를 키로 생성
            const key = `${no1}-${no2}-${no3}`;

            // 키가 없다면 초기화
            if (!rowColDataMap.has(key)) {
                rowColDataMap.set(key, { no1, no2, no3, values: [] });
            }

            // 해당 키에 `{ up_time, value }` 객체 추가
            rowColDataMap.get(key).values.push({ up_time, value });
        });
    });

    // 테마에 따른 색상 설정
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const btnBgColor = useColorModeValue(lightTheme_colors.btnBgColor, darkTheme_colors.btnBgColor);

    const highlightColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);


    return (
        <Box w={"100%"} h={"100%"} overflow="auto" p={1}>
            {isToggled ? (
                <Box>
                    <SBBTable
                        pageList={SBBTablePageList}
                        SSBdata={SBBTabledata.current}
                        handleToggle={handleToggle} // handleToggle 전달
                    />
                </Box>
            ) : (
                <Table variant="simple" size="sm" textAlign="center" border="1px solid black" bg={bgColor} >
                    <Thead>
                        <Tr bg={highlightColor}>
                            <Th>
                                <Button
                                    h={"100%"}
                                    onClick={() => setIsToggled(!isToggled)}
                                    fontSize="md"
                                    textAlign="center"
                                    bg={highlightColor}
                                >
                                    {isToggled ? "번호" : "번호"} {/* 텍스트도 토글 */}
                                </Button>
                            </Th>
                            {[...tableData2.map((item) => item.up_time)].map((up_time, idx) => (
                                <Th key={idx}>{up_time}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Array.from(rowColDataMap.entries()).map(([key, { no1, no2, no3, values }], rowIndex) => (
                            <Tr
                                key={key}
                                bg={rowIndex % 2 === 0 ? bgColor : btnBgColor} // 짝수 행은 연한 회색, 홀수 행은 흰색
                            >
                                <Td textAlign="right" bg={highlightColor}>
                                    {`${no1} - ${no2} - ${no3}`}
                                </Td>
                                {values.map(({ value }: { value: number }, idx: number) => (
                                    <Td key={idx}>
                                        <Text color={value < 10 ? "red" : value < 100 ? "blue" : mainFontColor} textAlign="right">
                                            {typeof value === "number" ? value.toFixed(1) : value} {/* value가 숫자일 때만 toFixed 호출 */}
                                        </Text>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
}


