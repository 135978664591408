import { atom } from "recoil";
import { IUserInfo } from "../api/user";


//login
export const IsLoginAtom = atom<Boolean>({
    key: "IsLoginAtom",
    default: false,
})


//User Info
export const UserInfoDefaultData: IUserInfo = {
    pk: 0,
    username: "",
    authority: "user",
    distributor: {
        pk: 0,
        name: "",
        commission: 0,
    },
    reload: "",
    login_verified: false,
    login_ip: "",
    login_datetime: "",
    logout_datetime: "",
    created_at: "",
}

export const UserInfoAtom = atom<IUserInfo>({
    key: "UserInfoAtom",
    default: UserInfoDefaultData,
})


export const IsUserLoginVerifiedAtom = atom<Boolean>({
    key: "IsUserLoginVerifiedAtom",
    default: false,
})

export const IsManagerAtom = atom<Boolean>({
    key: "IsManagerAtom",
    default: false,
})

