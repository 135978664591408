import { Button, Center, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, useColorModeValue, useToast } from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useSetRecoilState } from "recoil";
import { IsLoginAtom } from "../atoms/user";
import { IUserLogin, IuserLoginReturn, userLogIn } from "../api/user";
import { useMutation } from "@tanstack/react-query";
import { IseverDetailReturn } from "../api/base";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { darkTheme_colors, lightTheme_colors } from "../theme";

/*
로그인 모달창. 
- 로그인 성공시 
    토스트 메세지를 띄우고,
    토큰을 로컬스토리지에 저장하고,
    로그인 상태(ricoil) 를 true로 변경한다.
- 로그인 실패시
    에러메세지를 띄운다.
*/

interface ILoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function LoginModal({ isOpen, onClose }: ILoginModalProps) {
    const toast = useToast();
    const setIsLogin = useSetRecoilState(IsLoginAtom)
    const { register, handleSubmit, formState, reset } = useForm<IUserLogin>();
    const [serverdetailmessage, setserverdetailmessage] = useState("")

    // 패스워드 보이기 숨기기
    const [passwordshow, setPasswordshow] = useState(false)
    const passwordshowHandleClick = () => setPasswordshow(!passwordshow)

    // 로그인 버튼 눌럿을때 동작
    const onSubmit = ({ username, password }: IUserLogin) => {
        // setDupliactePrevant()
        mutation.mutate({ username, password })
    }

    const mutation = useMutation(userLogIn, {
        onSuccess: (data: IuserLoginReturn) => {
            toast({
                position: 'top',
                title: "로그인 성공! ",
                description: "방문을 환영합니다. ",
                status: "info",
                isClosable: true,
            });

            localStorage.setItem("token", data.token)
            setIsLogin(true)
            reset();
            onClose();
        },
        onError: (data: IseverDetailReturn) => {
            setserverdetailmessage(data.response.data.detail)
        }
    })


    // 테마 관련 코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const btnBgColor = useColorModeValue(lightTheme_colors['btnBgColor'], darkTheme_colors['btnBgColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bgColor={bgColor}>
                <ModalHeader>로그인</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={"4"}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaUserAlt color='gray.300' />}
                            />
                            <Input
                                {...register("username", { required: "아이디가 입력되지 않았습니다.", })}
                                type='text'
                                placeholder='아이디를 입력하세요'
                                bgColor={inputBgColor}
                            />
                        </InputGroup>
                        {formState.errors.username ?
                            <Center mt={"8"} textColor={"red"}>{formState.errors.username?.message}</Center> : ""
                        }
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("password", { required: "패스워드가 입력되지 않았습니다.", })}
                                type={passwordshow ? 'text' : "password"}
                                placeholder='패스워드를 입력하세요'
                                bgColor={inputBgColor}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' bgColor={btnBgColor} onClick={passwordshowHandleClick} >
                                    {passwordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.password ?
                            <Center mt={"8"} textColor={"red"}>{formState.errors.password?.message}</Center> : ""
                        }
                        {mutation.isError ?
                            <Center mt={"8"} textColor={"red"}>{serverdetailmessage}</Center> : ""
                        }
                        <Button isLoading={mutation.isLoading} type="submit" bgColor={btnBgColor} w="100%" >
                            로그인
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}