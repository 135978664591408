import { Box, Center, Grid, GridItem, Image, VStack, Button, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useToast, useColorModeValue } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useLoginOnlyPage from "../components/LoginProtectedPage";
import { useState, useRef } from 'react';
import { formatDate } from "../lib/utils";
import { getRaceDetail } from "../api/racelist";
import { postUserCoin } from "../api/userCoin";
import { IseverDetailReturn } from "../api/base";
import { darkTheme_colors, lightTheme_colors } from "../theme";

export default function BuyRace() {
    useLoginOnlyPage()
    const { racePk } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const [today, settoday] = useState<Date>(new Date());

    const { isLoading: racedataisLoading, data: racedata } = useQuery([`racedetail`, racePk], getRaceDetail);

    function getRegin(type: string) {
        return {
            seoul: "서울 ",
            busan: "부산 ",
            jejuoo: "제주 ",
        }[type];
    }

    //경기구매
    const { isOpen: recebuyisOpen, onOpen: recebuyonOpen, onClose: recebuyonClose } = useDisclosure()
    const recebuycancelRef = useRef(null)

    const raceresult = () => {
        racebuymutation.mutate({
            change_coin: -5,
            reason: "buied_race",
            buied_race: Number(racePk),
        })
    }

    const racebuymutation = useMutation(postUserCoin, {
        onSuccess: (data) => {
            recebuyonClose()
            toast({
                position: 'top',
                title: "구매성공 ",
                description: `${racedata.departure_datetime.split('T')[1].slice(0, 5)} ${getRegin(racedata.region)} ${racedata.number} 경주정보를 구매했습니다.`,
                status: "success",
                isClosable: true,
            });
            navigate("/")
        },
        onError: (data: IseverDetailReturn) => {
            console.log("BuyRace racebuymutation error(detail):", data.response.data.detail)
        }
    })

    //날짜구매
    const { isOpen: daybuyisOpen, onOpen: daybuyonOpen, onClose: daybuyonClose } = useDisclosure()
    const daybuycancelRef = useRef(null)

    const dayresult = () => {
        daybuymutation.mutate({
            change_coin: -60,
            reason: "buied_race_day",
            buied_race: Number(racePk),
            buied_day: `${formatDate(today)}`
        })
    }

    const daybuymutation = useMutation(postUserCoin, {
        onSuccess: (data) => {
            daybuyonClose()
            toast({
                position: 'top',
                title: "구매성공 ",
                description: `${today.getMonth() + 1}월 ${today.getDate()}일 경주정보를 구매했습니다.`,
                status: "success",
                isClosable: true,
            });
            navigate("/")
        },
        onError: (data: IseverDetailReturn) => {
            console.log("BuyRace racebuymutation error(detail):", data.response.data.detail)
        }
    })

    // 테마 관련 코드
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const btnBgColor = useColorModeValue(lightTheme_colors.btnBgColor, darkTheme_colors.btnBgColor);
    const btnTextColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const dialogBgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const dialogTextColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);

    return (
        <Grid
            padding={"0.2rem"}
            h='100%'
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(1, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} rowSpan={1} bg={mainBgColor} >
                <Box borderRadius={"xl"} width={"100%"} height={"100%"} >
                    <Image objectFit={"cover"} h={"41vh"} width={"98.5vw"} borderRadius={"xl"} src="https://c.pxhere.com/images/06/83/f6bb4ba69449e6b1fb5600bb1daf-1588287.jpg!d" />
                </Box>
            </GridItem>
            <GridItem padding={"0.5rem"} borderRadius={"xl"} rowSpan={1} bg={mainBgColor} color={btnTextColor}>
                <VStack height={"100%"} justifyContent={"center"} bg={btnBgColor} borderRadius={"xl"}>
                    {
                        racedataisLoading ? (
                            <Center>로딩중!</Center>
                        ) : (
                            <>
                                <Button onClick={recebuyonOpen} width={"50%"} colorScheme='green' size='lg'>
                                    {racedata.departure_datetime.split('T')[1].slice(0, 5)} {getRegin(racedata.region)} {racedata.number} 경주 개별 구매
                                </Button>
                                <Button onClick={daybuyonOpen} width={"50%"} colorScheme='green' size='lg'>
                                    {today.getMonth() + 1}월 {today.getDate()}일 경주 전체 구매
                                </Button>
                            </>
                        )
                    }
                </VStack>
            </GridItem>
            {
                racedataisLoading ? (
                    <Center>로딩중!</Center>
                ) : (
                    <>
                        <AlertDialog
                            isOpen={recebuyisOpen}
                            leastDestructiveRef={recebuycancelRef}
                            onClose={recebuyonClose}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent bg={dialogBgColor} color={dialogTextColor}>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        구매 확인(구매후 취소 불가)
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        {racedata.departure_datetime.split('T')[1].slice(0, 5)} {getRegin(racedata.region)} {racedata.number} 경주정보를 구매합니다.
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button ref={recebuycancelRef} onClick={recebuyonClose}>
                                            취소
                                        </Button>
                                        <Button colorScheme='red' onClick={raceresult} ml={3}>
                                            확인
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>

                        <AlertDialog
                            isOpen={daybuyisOpen}
                            leastDestructiveRef={daybuycancelRef}
                            onClose={daybuyonClose}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent bg={dialogBgColor} color={dialogTextColor}>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        구매 확인(구매후 취소 불가)
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        {today.getMonth()}월 {today.getDate()}일 경주정보를 구매합니다.
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button ref={daybuycancelRef} onClick={daybuyonClose}>
                                            취소
                                        </Button>
                                        <Button colorScheme='red' onClick={dayresult} ml={3}>
                                            확인
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </>
                )
            }
        </Grid>
    )
}
