import { Circle, Flex, Square, Text, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { getHorseGradientStr, getHorseTextColor } from "../../../lib/utils";
import { Fragment } from "react";
import { motion } from "framer-motion";

export default function TopFive({ top5Data }: { top5Data: [] }) {
    let TopNumberCount = top5Data.length > 5 ? 5 : top5Data.length;

    // 테마에 맞는 색상 설정
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);

    return (
        <Flex h={"100%"} justifyContent={"space-around"} alignItems={"center"} color={mainFontColor}>
            {top5Data === undefined && (
                <Square fontSize={"1.5rem"}>로딩중입니다.</Square>
            )}

            {top5Data.length === 0 && (
                <Square fontSize={"1.5rem"}>데이터 준비중입니다.</Square>
            )}

            {top5Data.length > 0 && (
                <>
                    {top5Data.map((data: any, index: number) =>
                        index < TopNumberCount && (
                            <Fragment key={`${data[0]}`}>
                                <motion.div
                                    layout
                                    key={`${data[0]}`} // key 값 추가
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, ease: "easeInOut" }}
                                >
                                    <Circle
                                        size='3rem'
                                        fontSize={"1.5rem"}
                                        boxShadow='dark-lg'
                                        color={getHorseTextColor(data[0])}
                                        bgGradient={getHorseGradientStr(data[0])}
                                    >
                                        {data[0]}
                                    </Circle>
                                </motion.div>

                                {index < TopNumberCount - 1 && top5Data[index][1] === top5Data[index + 1][1] && (
                                    <Text fontSize={"1.5rem"}>=</Text>
                                )}
                            </Fragment>
                        )
                    )}
                </>
            )}
        </Flex>
    );
}
