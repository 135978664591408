import { createBrowserRouter } from "react-router-dom";


import LoginFirst from "./contents/LoginFirst";
import Root from "./contents/Root";
import UserMain from "./contents/UserMain/UserMain";
import DepasitCoin from "./contents/DepasitCoin";
import BuyRace from "./contents/BuyRace";
import RaceInfo from "./contents/RaceInfo/RaceInfo";
import TESTMain from "./contents/beadang/BDMain";



const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <UserMain />
            },
            {
                path: "loginfirst",
                element: <LoginFirst />,
            },
            {
                path: "raceinfo/:racePk",
                element: <RaceInfo />,
            },
            {
                path: "depasitcoin",
                element: <DepasitCoin />,
            },
            {
                path: "buyrace/:racePk",
                element: <BuyRace />,
            },
            {
                path: "manager_test/",
                element: <TESTMain />,
            },
        ],

    }
]);
export default router;