import { atom } from "recoil";
import { IUserCoins } from "../api/userCoin";

//User Coins
export const UserCoinsAtom = atom<IUserCoins>({
    key: "UserCoinsatom",
    default: {
        "coins_data": [],
        "total_coin": 0,
    }
})
