import { atom } from "recoil";
import { IRacelist } from "../api/racelist";

// DoingRace
export const DoingRaceAtom = atom<IRacelist>({
    key: "DoingRaceAtom",
    default: {
        pk: 0,
        nationality: "",
        region: "",
        number: 0,
        hourses: 0,
        departure_datetime: "",
        result: "",
        situation: "",
        cancel: "",
        is_paied: false,
    }
})




// beadang page
const SelectedRaceDefaultData: IRacelist = {
    "pk": 0,
    "departure_datetime": "",
    "nationality": "",
    "region": "",
    "number": 0,
    "hourses": 0,
    "situation": "normal",
    "result": "",
    "cancel": "[]",
}

export const SelectedRaceAtom = atom<IRacelist>({
    key: "SelectedRaceAtom",
    default: SelectedRaceDefaultData,
})
